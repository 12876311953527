/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-basicLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.ant-pro-basicLayout:not('.ant-pro-basicLayout-mobile') {
  /* 滚动条滑块 */
}
.ant-pro-basicLayout:not('.ant-pro-basicLayout-mobile') ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ant-pro-basicLayout:not('.ant-pro-basicLayout-mobile') ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
.ant-pro-basicLayout:not('.ant-pro-basicLayout-mobile') ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
.ant-pro-basicLayout .ant-layout-header:not(.ant-pro-top-menu) {
  background: #fff;
}
.ant-pro-basicLayout .ant-layout-header.ant-pro-fixed-header {
  position: fixed;
  top: 0;
}
.ant-pro-basicLayout-content {
  position: relative;
  margin: 24px;
  transition: all 0.2s;
}
.ant-pro-basicLayout-content .ant-pro-page-header-wrap {
  margin: -24px -24px 0;
}
.ant-pro-basicLayout-content-disable-margin {
  margin: 0;
}
.ant-pro-basicLayout-content-disable-margin > div > .ant-pro-page-header-wrap {
  margin: 0;
}
.ant-pro-basicLayout-content > .ant-layout {
  max-height: 100%;
}
.ant-pro-basicLayout .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: 100vh;
  overflow: hidden;
  transform: rotate(0);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children {
  min-height: calc(100vh - 64px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: calc(100vh - 64px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 64px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 64px - 64px);
}
