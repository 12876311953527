/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-setting-drawer-content {
  position: relative;
  min-height: 100%;
}
.ant-pro-setting-drawer-content .ant-list-item span {
  flex: 1;
}
.ant-pro-setting-drawer-block-checbox {
  display: flex;
}
.ant-pro-setting-drawer-block-checbox-item {
  position: relative;
  margin-right: 16px;
  border-radius: 2px;
  cursor: pointer;
}
.ant-pro-setting-drawer-block-checbox-item img {
  width: 48px;
}
.ant-pro-setting-drawer-block-checbox-selectIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-left: 24px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;
}
.ant-pro-setting-drawer-block-checbox-selectIcon .action {
  color: #1890ff;
}
.ant-pro-setting-drawer-color_block {
  display: inline-block;
  width: 38px;
  height: 22px;
  margin: 4px;
  margin-right: 12px;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
}
.ant-pro-setting-drawer-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.ant-pro-setting-drawer-handle {
  position: absolute;
  top: 240px;
  right: 300px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  background: #1890ff;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  pointer-events: auto;
}
.ant-pro-setting-drawer-production-hint {
  margin-top: 16px;
  font-size: 12px;
}
.ant-pro-setting-drawer-content .theme-color {
  margin-top: 24px;
  overflow: hidden;
}
.ant-pro-setting-drawer-content .theme-color .theme-color-title {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 22px;
}
.ant-pro-setting-drawer-content .theme-color .theme-color-block {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}
