/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-page-header-wrap-children-content {
  margin: 24px 24px 0;
}
.ant-pro-page-header-wrap-tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.ant-pro-page-header-wrap-page-header-warp {
  background-color: #fff;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-detail {
  display: flex;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-row {
  display: flex;
  width: 100%;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-title-content {
  margin-bottom: 16px;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-title,
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-content {
  flex: auto;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent,
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-main {
  flex: 0 1 auto;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-main {
  width: 100%;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-title {
  margin-bottom: 16px;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-logo {
  margin-bottom: 16px;
}
.ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
  min-width: 242px;
  margin-left: 88px;
  text-align: right;
}
@media screen and (max-width: 1200px) {
  .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-row {
    display: block;
  }
  .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-action,
  .ant-pro-page-header-wrap-main .ant-pro-page-header-wrap-extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .ant-pro-page-header-wrap-detail {
    display: block;
  }
  .ant-pro-page-header-wrap-extraContent {
    margin-left: 0;
  }
}
