/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-grid-content {
  width: 100%;
  min-height: 100%;
  transition: 0.3s;
}
.ant-pro-grid-content.wide {
  max-width: 1200px;
  margin: 0 auto;
}
